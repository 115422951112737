import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import translationFr from "./../Locales/fr/translation.json";
import {
  format as formatDate,
  formatRelative,
  formatDistance,
  isDate,
} from "date-fns";
import translationAr from "./../Locales/ar/translation.json";
import moment from "moment";

i18n
  .use(detector)
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en-US",
    debug: false,

    //  lng: "fr",

    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
      format: function (value, formatting, lng) {
        if (value instanceof Date) return moment(value).format(formatting);
        return value.toString();
      },
    },

    react: {
      useSuspense: false,
      wait: false,
    },

    resources: {
      fr: {
        translations: translationFr,
      },
      //   "en-US": {
      //     translations: translationEn,
      //   },
      ar: {
        translations: translationAr,
      },
    },
    ns: ["translations"],
    defaultNS: "translations",
    detection: {
      order: ["cookie", "localStorage", "querystring", "navigator"],
      caches: ["cookie", "localStorage"],
    },
  });

export default i18n;
